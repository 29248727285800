<template>
    <div>
        <v-sheet class="px-6 py-12 pb-1">
            <div class="container-">
                <div class="d-flex flex-wrap justify-space-between">
                    <v-avatar size="120" class="mr-6">
                        <img
                            class=""
                            src="@/assets/images/faces/2.jpg"
                            alt=""
                        />
                    </v-avatar>
                    <div class="flex-1">
                        <div class="d-flex align-start justify-space-between">
                            <div class="mb-3">
                                <h5 class="mr-2 mb-0">
                                    Alex Padatos

                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{on, attrs}"
                                        >
                                            <v-icon
                                                class="body-1"
                                                color="primary"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                mdi-check-decagram</v-icon
                                            >
                                        </template>
                                        <span>Verified</span>
                                    </v-tooltip>
                                </h5>
                                <div class="mt-2">
                                    <v-chip class="" color="orange" outlined small>
                                        Massage
                                    </v-chip>
                                    <v-chip class="ml-1" color="orange" outlined small>
                                        Physiotherapy
                                    </v-chip>
                                </div>
                                <div class="mt-3">
                                    <v-rating
                                        v-model="help_rating"
                                        background-color="primary"
                                        color="primary"
                                        readonly
                                        dense
                                        class="mb-3-"
                                    ></v-rating>
                                </div>
                                <!-- <p class="text--disabled mb-0">Amanda Nash</p> -->
                            </div>
                            <!-- <div>
                                <v-chip class="" color="orange" outlined small>
                                    Massage
                                </v-chip>
                                <v-chip class="ml-1" color="orange" outlined small>
                                    Physiotherapy
                                </v-chip>
                            </div> -->
                        </div>
                        <div class="d-flex mb-2">
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium">73 </span
                                ><span class="body-2 text--disabled"
                                    >Completed Sessions</span
                                >
                            </p>
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium">3 </span
                                ><span class="body-2 text--disabled"
                                    >Years Experience</span
                                >
                            </p>
                            <!-- <p class="ma-0 mr-8">
                                <span class="font-weight-medium">1540 </span
                                ><span class="body-2 text--disabled"
                                    >Following</span
                                >
                            </p> -->
                        </div>
                        <p class="text--disabled">
                            An experienced therapist with expertise in sports rehabilitation.
                        </p>                        
                    </div>
                    <div class="flex-1 text-right">
                        
                        <v-btn
                            v-if="favorite"
                            class="mr-2 shadow-sm"
                            fab
                            color="danger"
                            small
                            dark        
                            @click.stop="favorite=!favorite"                    
                        >
                            <v-icon>mdi-heart</v-icon>
                        </v-btn>
                            <v-btn
                            v-else
                            class="mr-2 shadow-sm"
                            small
                            fab                                                         
                            @click.stop="favorite=!favorite"
                        >
                            <v-icon>mdi-heart-outline</v-icon>
                        </v-btn>
                        <v-btn fab class="mr-2" small depressed>
                            <v-icon>mdi-email-outline</v-icon>
                        </v-btn>
                        <v-btn class="ma-2 text-capitalize" color="#eb8e45" style="opacity: 0.9; color: white" depressed dark @click.stop="$emit('clickbook')">
                            <v-icon>
                                mdi-file-document-plus-outline
                            </v-icon>
                            &nbsp;
                            Ραντεβού
                        </v-btn>
                        <!-- <v-menu offset-y>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                    fab
                                    color=""
                                    small
                                    depressed
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-icon>mdi-eye</v-icon> View As
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-icon
                                            >mdi-comment-text-multiple</v-icon
                                        >
                                        Messages
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-icon>mdi-account-settings</v-icon>
                                        Account Settings
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu> -->
                    </div>                    
                </div>                
            </div>
        </v-sheet>
        <div>
            <v-chip
                v-for="(selection, i) in therapistServices"
                :key="i"
                :color="'#ffb56b'"
                dark                                    
                class="ma-1"                
                >
                <!-- <v-icon
                    left
                    small
                >
                    mdi-plus
                </v-icon> -->
                {{ selection.title }} {{ selection.options.find(o=>o.default).price + '€ / ' + selection.options.find(o=>o.default).duration  }}
            </v-chip>
        </div>
        <base-card class="transparent">
            <v-tabs v-model="tab" background-color="">
                <v-tab v-for="item in items" :key="item.tab">
                    {{ item.tab }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="transparent">                                
                <v-tab-item class="transparent">
                    <v-radio-group v-model="selectedTimeslot">
                        <table style="width: 100%; text-align: center;">
                        <tbody>
                            <tr>
                                <td  v-for="(selection, i) in selectedDates" :key="i">{{getDateString(selection)}}</td>                                
                            </tr>
                            <tr v-for="timeslot in seasons" :key="timeslot">
                                <td class="text-center"  v-for="(selection, i) in selectedDates" :key="i" style="text-align: center;">
                                    <v-radio :disabled="timeslot == '02:00' || timeslot == '03:00'" :value="getDateString(selection) + ' ' + timeslot " color="purple" class="ma-0 pa-0" hide-details hide-spin-buttons >
                                        <template v-slot:label>
                                            <span v-if="timeslot == '02:00' || timeslot == '03:00'" style="text-decoration: line-through;">{{timeslot}}</span>
                                            <span v-else style="color: purple">{{timeslot}}</span>
                                        </template>
                                    </v-radio>
                                </td>                                                                
                            </tr>                            
                        </tbody>
                    </table>
                    </v-radio-group>  
                </v-tab-item>
                <v-tab-item class="transparent">
                    <v-row>
                                <v-col cols="12" md="12">
                                    <base-card>
                                        <!-- <v-card-title>
                                            <div class="card-title">Additional Photos</div><span>
                                                <v-btn class="text-capitalize"                                                                                     
                                                    color="primary"                                 
                                                    dark             
                                                    small   
                                                    style="margin-top: -20px; margin-left: 20px;"
                                                                                                                                     
                                                >                                                    
                                                    + Upload
                                                </v-btn>
                                            </span>
                                        </v-card-title> -->
                                        <v-card-text>
                                            <vueper-slides
                                                class="no-shadow"
                                                :visible-slides="4"
                                                slide-multiple
                                                fixed-height="350px"
                                                :gap="2"
                                                :slide-ratio="1 / 2"
                                                :dragging-distance="200"
                                                :breakpoints="{
                                                    800: {visibleSlides: 2, slideMultiple: 2},
                                                    400: {visibleSlides: 1}
                                                }"
                                            >
                                                <!-- <vueper-slide v-for="(slide, index) in sliderImage" :key="index" :image="slide.img" /> -->
                                                <vueper-slide
                                                    v-for="(card, index) in cards"
                                                    :key="index"
                                                >
                                                    <template v-slot:content>
                                                        <base-card class="h-full">
                                                            <div class="">
                                                                <img
                                                                    class="md:w-6/12"
                                                                    :src="card.img"
                                                                />
                                                            </div>
                                                        </base-card>
                                                    </template>
                                                </vueper-slide>
                                            </vueper-slides>
                                        </v-card-text>
                                    </base-card>
                                </v-col>
                            </v-row>

                </v-tab-item>
            </v-tabs-items>
        </base-card>
    </div>
</template>
<script>
import avatarGroupCard from '@/components/card/AvatarGroupCard';
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
    name: 'profiledetails',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Profile 1'
    },
    components: {
        avatarGroupCard,
        VueperSlides, 
        VueperSlide 
    },
    props: {
        selectedDates: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            selectedTimeslot: null,
            favorite: false,


            seasons: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00'],

            help_rating: 4,


            therapistServices: [
                {
                    title: "Μασάζ Προσώπου",                    
                    options: [
                        { //€
                            price: "15",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "30",
                            duration: "60'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
                {
                    title: "Μασάζ Πλάτης",
                    options: [
                        { //€
                            price: "20",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "60'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
                {
                    title: "Μασάζ Αυχένα",
                    options: [
                        { //€
                            price: "10",
                            duration: "15'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "30'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "30'"
                },
                {
                    title: "Shiatsu",
                    options: [
                        { //€
                            price: "10",
                            duration: "15'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "30'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "30'"
                },
                {
                    title: "Λεμφικό Μασάζ",
                    options: [
                        { //€
                            price: "30",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "40",
                            duration: "60'",
                            default: true,
                        },
                        { //€
                            price: "60",
                            duration: "90'",
                            default: false,
                        }                    
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
            ],


            cards: [
            {
                title: 'Beats Headphone',
                details: 'Enjoy Premium Sound',
                price: '39',
                img: require('@/assets/images/products/headphone-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            },
            {
                title: 'Beats Headphone',
                details: 'Enjoy Premium Sound',
                price: '39',
                img: require('@/assets/images/products/headphone-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            }
        ],
        slides: [
            {image: require('@/assets/images/products/headphone-1.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')},
            {image: require('@/assets/images/products/iphone-1.jpg')},
            {image: require('@/assets/images/products/speaker-1.jpg')},
            {image: require('@/assets/images/products/watch-2.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')},
            {image: require('@/assets/images/products/headphone-1.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')}
        ],

            tab: null,
            items: [
                // {tab: 'Timeline', content: 'Tab 1 Content'},                
                {tab: 'Availability', content: 'Tab 2 Content'},
                {tab: 'Photos', content: 'Tab 2 Content'}
                
            ],
            infos: [
                {
                    title: 'Country',
                    subtitle: 'USA',
                    icon: 'mdi-home'
                },
                {
                    title: 'Relationship',
                    subtitle: 'Single',
                    icon: 'mdi-heart'
                },
                {
                    title: 'Form',
                    subtitle: 'New York',
                    icon: 'mdi-map'
                },
                {
                    title: 'Studied @',
                    subtitle: 'University Of UI Lib',
                    icon: 'mdi-school'
                },
                {
                    title: 'Lives In',
                    subtitle: 'Texas',
                    icon: 'mdi-google-maps'
                },
                {
                    title: 'Followers',
                    subtitle: '120k Followers',
                    icon: 'mdi-bell'
                }
            ],
            friends: [
                {
                    img: require('@/assets/images/faces/1.jpg'),
                    title: 'Tim Clarkson',
                    subtitle:
                        '<span class="text--disabled"> 2 mutual friend </span>',
                    iconColor: 'warning'
                },
                {
                    img: require('@/assets/images/faces/2.jpg'),
                    title: 'Jhon Weaver',
                    subtitle:
                        '<span class="text--disabled"> 2 mutual friend </span>',
                    iconColor: ''
                },
                {
                    img: require('@/assets/images/faces/3.jpg'),
                    title: 'Kane Frost',
                    subtitle:
                        '<span class="text--disabled"> 2 mutual friend </span>',
                    iconColor: 'warning'
                },
                {
                    img: require('@/assets/images/faces/4.jpg'),
                    title: 'Kane Frost',
                    subtitle:
                        '<span class="text--disabled"> 2 mutual friend </span>',
                    iconColor: 'warning'
                },
                {
                    img: require('@/assets/images/faces/5.jpg'),
                    title: 'Drow Ranger',
                    subtitle:
                        '<span class="text--disabled"> 2 mutual friend </span>',
                    iconColor: 'warning'
                },
                {
                    img: require('@/assets/images/faces/4.jpg'),
                    title: 'Juggernaut Frost',
                    subtitle:
                        '<span class="text--disabled"> 2 mutual friend </span>',
                    iconColor: ''
                }
            ],
            images: [
                {img: require('@/assets/images/gallery/sq-10.jpg')},
                {img: require('@/assets/images/gallery/sq-11.jpg')},
                {img: require('@/assets/images/gallery/sq-12.jpg')},
                {img: require('@/assets/images/gallery/sq-13.jpg')}
            ],
            country: [
                {
                    img: require('@/assets/images/country/dubai.jpg'),
                    title: 'Dubai',
                    subtitle: '<span class="text--disabled"> 2 days ago </span>'
                },
                {
                    img: require('@/assets/images/country/new_york.jpg'),
                    title: 'New York, USA',
                    subtitle:
                        '<span class="text--disabled"> 2 years ago </span>'
                },
                {
                    img: require('@/assets/images/country/china.jpg'),
                    title: 'Shanghai, China',
                    subtitle:
                        '<span class="text--disabled"> 11 months ago </span>'
                },
                {
                    img: require('@/assets/images/country/paris.jpg'),
                    title: 'Paris, France',
                    subtitle:
                        '<span class="text--disabled"> 11 months ago </span>'
                }
            ]
        }
    },
    methods: {
        getDateString: function(dt_string) {
            var weekday=new Array(7);
            weekday[1]="Mon";
            weekday[2]="Tue";
            weekday[3]="Wed";
            weekday[4]="Thu";
            weekday[5]="Fri";
            weekday[6]="Sat";
            weekday[0]="Sun";
            
            var dt = new Date(dt_string);
            const dayWeek = dt.getUTCDay();
            
            return `${weekday[dayWeek]}, ${dt.getUTCDate()}/${dt.getUTCMonth()+1}`;
        }
    }
}
</script>
<style lang="scss" scoped>
.v-radio {
    display: block !important;
}

.ul-widget-profile-img {
    position: relative;
}
.ul-widget-profile-img:after {
    // content: "";
    // position: absolute;
    // top: 44px;
    // left: 50%;
    // width: 1px;
    // height: calc(100% - 30px);
    // background: #B3C0CE;
    content: '';
    position: absolute;
    top: 44px;
    left: 50%;
    width: 1px;
    height: calc(100% - 30px);
    background: #b3c0ce;
}
</style>
